import i18n from "i18next";
import {ProgramDTO} from "./ProgramDTO";

export interface MoneylenderDTOToDisplay {
    id: string
    businessName: string
    country: string
    city: string
    selected: boolean
}

export interface MoneylenderDTO {
    vatNumber: string
    businessName: string
    country: string
    city: string
}


export interface CreateCreditLineMoneylenderRequestDTO {
    creditLineId?: number
    creditLineName: string
    vatNumber: string
    buyerId: string
    buyerRelationId: string
    currency: string
    amount: number
    revolving: boolean
    expiryDate: Date
    programs: { programName: string }[]
    visibility: boolean
    moneylendersWhitelist: string[]
    warnings: number
    discount: number
    durationPayables: number
    durationPayablesType: DurationPayablesType
    secondaryMarket: number
    allPrograms: boolean
    sublimit: number
    iban: string
}

export interface CreateCreditLineMoneylenderDTO {
    creditLineName: string
    vatNumber: string
    buyerId: string
    businessName: string
    buyerRelationId: string
    currency: string
    amount: number
    revolving: boolean
    expiryDate: Date
    programs: { programName: string }[]
    visibility: boolean
    moneylenderWhiteList: string[]
    warnings: number
    discount: number
    durationPayables: number
    durationPayablesType: DurationPayablesType
    secondaryMarket: number
    allPrograms: boolean
    sublimit: number
    iban?: string
    swift?: string
    creationDate?: Date
    residualAmount?: number
    creditLineCode: string
    originalAmount: number
}

export interface CreditLineMoneylenderDTO {
    id: number
    creditLineName: string
    revolving: boolean
    amount: number
    currency: string
    buyer: string
    buyerVatNumber: string
    affiliateProgramNumber: number
    secondaryMarket: number
    prices: number
    expiryDate: Date
    creditLineCode: string
    uncoveredPrograms: number
    buyerMarketplaceCode: string
}

export enum configuration_prices_steps {
    LIST_CONFIGURATION = "listConfiguration",
    CREATE_CONFIGURATION_PRICES = "createConfigurationPrices",
    DETAILS_CONFIGURATION = "detailsConfiguration",
    CREDIT_LINE_DETAILS = "creditLineDetails",
}

export enum DelayRateType {
    FIXED = "FIXED",
    VARIABLE = "VARIABLE",
    ADDITIVE = 'ADDITIVE'
}

export enum DurationPayablesType {
    MAX_RESIDUAL_DURATION = "MAX_RESIDUAL_DURATION",
    MAX_ORIGINAL_DURATION = "MAX_ORIGINAL_DURATION",
}

export const delayRateInputValues = [
    {key: DelayRateType.FIXED, label: i18n.t("SCFProgram.modal.dropdown.delayRateFixed")},
    {key: DelayRateType.VARIABLE, label: i18n.t("SCFProgram.modal.dropdown.delayRateFlex")},
];


export interface ConfigurationRequest {
    configurationId?: string
    creditLineId?: number
    allPrograms: boolean
    deleteId?: string
    default?: SingleConfiguration
    rates?: SingleConfiguration[]
    countries?: SingleConfiguration[]
    programs?: string[]
    esgRates?: EsgPriceConfigurationDTO[]
}

export interface SingleConfiguration {
    delayRateType: string
    fixedBaseRate: number
    variableBaseRate: number
    variableSpreadRate: number
    rating?: number
    country?: string
}

export interface EsgPriceConfigurationDTO{
    esgConfigurationId: number;
    rating: string;
    delayRateType: string;
    variableBaseRate: number | null;
    fixedBaseRate: number | null;
    variableSpreadRate: number | null;  
    additiveBaseRate: number;  
    esgCertificationType?: string;
}

export interface MoneylenderInvoiceDTO {
    documentId: string
    id: number
    vendor: string
    amount: number
    currency: string
    status: string
    tax: number
    expiryDate: string
}

export enum invoiceStatus {
    ANTICIPATED = "ANTICIPATED",
    PAYMENT = "PURCHASE"
}

export interface MoneylenderInvoiceDetailsDTO {
    disposalNumber: number
    disposalDate: string
    status: string
    currency: string
    amountInvoice: number
    purchasePrice: number
    vendorId: string
    businessName: string
    AssignorId: string
    ReportId: string
    ReportBuyerId: string
    buyerId: string
    invoiceInfo: {
        documentId: string
        id: string
        emissionDate: string
        expiryDateFreeInterests: string
        expiryDateDocument: string
        interestTax: number
        interestDelay: number
        discountMode: number
        discountTax: number
        interestDiscount: number
        discountStartingDate: string
        isForcedDelayedInterest?:boolean
    }
}

export interface VendorInfo {
    vendorName: string
    vatNumber: string
    marketplaceCode: string
    processStatus: boolean
    lastModify: Date
}

export interface ResponseVendorProgramDTO {
    programId: string
    programDescription: string
    vendorName: boolean
}

export interface RequestEditVendorInfoDTO {
    vendorIDInMoneylenderSystem: string
    vendorVatNumber: string
    vendorID: string
    acceptanceProcess: boolean
    reproposed: boolean
    relations: VendorInfoRelation[] | null
    unlinkedBuyers: VendorInfoRelation[]
    moneylenderVatNumber: string
}

export interface VendorInfoRelation {
    buyerVatNumber?: string,
    buyerBusinessName?: string,
    vendorRelationId?: string
}

export interface ReminderResponseDTO {
    period: number
    invoices: ExpiringInvoice[]
}

export interface ExpiringInvoice {
    invoiceNumber: number
    amount: number
    currency: string
}

export interface ProgramsWithoutConfigurationDTO {
    allPrograms: boolean
    programs: ProgramDTO[]
}

export interface OperationInvoiceDTO {
    operationId: string
    operationDate: Date
    targetCompany: string
    operationType: string
    amount: number
    amountPaid: number
}

export enum IncomeOperationStatus {
    NOT_CASHED = "NOT_CASHED",
    CASHED = "CASHED",
    REFUSED = "REFUSED"
}

export interface IncomeOperationDTO {
    operationId: string
    registrationDate?: Date
    updateDate?: Date
    buyerBusinessName?: string
    currency?: string
    totalAmount?: number
    interestAmount?: number
    ibanOperation?: string
    invoiceNumber?: number,
    incomeStatus: IncomeOperationStatus
}

export interface IncomeInvoiceDTO {
    currency?: string,
    amountInvoice?: number,
    purchasePrice?: number,
    buyerBusinessName?: string,
    documentId: string
    emissionDate?: Date
    expiryDate: Date,
    lastExpiryDate?: Date,
    operationIdCreditCession: string
}

export interface DownloadVendorRegistryRequestDTO {
    vendorRegistry: DownloadVendorRegistryDTO[]
    vendorsNumber: number
}

export interface DownloadVendorRegistryDTO {
    businessName: string
    vatNumber: string
    country: string
    region: string
    link: string
    documentId: number
    selected: boolean
}

export interface VendorRegistryDocumentRequestDTO {
    vendors: string[]
    buyerId: string
}

export interface IncomesDocumentRequestDTO {
    operations: string[]
}

export interface DocumentZippedDTO {
    base64: string
    fileName: string
}
